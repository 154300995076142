/**
 * Created by panwei on 2017/11/23.
 */
import key from './key'
import { Resource } from '@ekuaibao/fetch'

const currencyAction = new Resource('/api/v2/currency')
const currencyEnumAction = new Resource('/api/v1/basedata/enumItems')

export function getAllCurrency() {
  return {
    type: key.GET_ALL_CURRENCY,
    payload: currencyEnumAction.GET('/byEnumCode/$enumCode', { enumCode: 'currency' }),
  }
}

export function updateRate(param) {
  return {
    type: key.UPDATE_RATE,
    payload: currencyAction.POST('', param),
  }
}

export function deleteRate(param) {
  return {
    type: key.DELETE_RATE,
    payload: currencyAction.DELETE('/$originalId', param),
  }
}
export function setDefaultRate(param) {
  return {
    type: key.SET_DEFAULT_RATE,
    payload: currencyAction.PUT('/$id/switchDefault/$isDefault', param),
  }
}
export function allowModifyRate(param) {
  return {
    type: key.ALLOW_MODIFY_RATE,
    payload: currencyAction.PUT('/config', param),
  }
}

export function getRateHistory(param) {
  return {
    type: key.GET_RATE_HISTORY,
    payload: currencyAction.GET('/history/$originalId/$numCode', param),
  }
}

export function saveCurrencyConfig(id) {
  return {
    type: key.SAVE_CURRENCY_CONFIG,
    payload: currencyAction.POST('/config/$id', { id }),
  }
}

export function getCurrencyRatesById(id) {
  return {
    type: key.GET_CURRENCY_RATES_BYID,
    payload: currencyAction.GET('/active/$id', { id }),
  }
}

export function getCurrencyInfoById(params) {
  return currencyEnumAction.GET('/$id', params)
}

export function getCurrencyModifiable() {
  return currencyAction.GET('/config/modifiable')
}
