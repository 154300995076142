/**
 * Created by panwei on 2017/11/21.
 */
export const ID = '@currency-manage'

export default {
  ID: ID,
  GET_ALL_CURRENCY: `${ID}/GET_ALL_CURRENCY`,
  UPDATE_RATE: `${ID}/UPDATE_RATE`,
  DELETE_RATE: `${ID}/DELETE_RATE`,
  SET_DEFAULT_RATE:`${ID}/SET_DEFAULT_RATE`,
  GET_RATE_HISTORY: `${ID}/GET_RATE_HISTORY`,
  ALLOW_MODIFY_RATE: `${ID}/ALLOW_MODIFY_RATE`,
  SAVE_CURRENCY_CONFIG:`${ID}/SAVE_CURRENCY_CONFIG`,
  GET_CURRENCY_RATES_BYID:`${ID}/GET_CURRENCY_RATES_BYID`
}
